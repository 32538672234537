import React from 'react'
import {ContentPageNode} from '../../types/content-types'

type Props = {
  page: ContentPageNode
  children: React.ReactNode
}

const Homepage = ({children}: Props) => {
  return <div className="homepage">{children}</div>
}

export default Homepage
